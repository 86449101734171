<template>
    <div id="printKwitansi">
        <div class="pt-8 pl-6 mt-5 ml-2 w-10 text-xs">
            <div class="flex flex-wrap justify-content-between flex-row grid">
                <div class="flex flex-column justify-content-start col">
                </div>
                <div class="flex justify-content-end col">
                    Depok, {{ getLongDate(detailDonasi.tgl_donasi) }}
                </div>
            </div>
            <table class="w-auto text-xs">
                <tr>
                    <td class="text-left w-3">No. Kwitansi</td>
                    <td class="text-center w-1">:</td>
                    <td class="text-left w-auto">{{ noKwitansi(detailDonasi.id) }}</td>
                </tr>
                <tr>
                    <td class="text-left">Telah diterima dari</td>
                    <td class="text-center">:</td>
                    <td class="text-left">{{ detailDonasi.nama_donatur }}</td>
                </tr>
                <tr>
                    <td class="text-left">Alamat</td>
                    <td class="text-center">:</td>
                    <td class="text-left">{{ detailDonasi.alamat_donatur }}</td>
                </tr>
                <tr>
                    <td class="text-left">Sejumlah</td>
                    <td class="text-center">:</td>
                    <td class="text-left">{{ formatCurency(detailDonasi.nominal) }}</td>
                </tr>
                <tr>
                    <td class="text-left">Terbilang</td>
                    <td class="text-center">:</td>
                    <td class="text-left">{{ currencyToText(detailDonasi.nominal) }}</td>
                </tr>
                <tr>
                    <td class="text-left">Keterangan</td>
                    <td class="text-center">:</td>
                    <td class="text-left">{{ detailDonasi.keterangan }}</td>
                </tr>
            </table>
            <div class="grid mt-2">
                <div class="text-center col">
                    <p>Yang Menyerahkan</p>
                    <p class="pt-3"></p>
                    <p>( {{ detailDonasi.nama_donatur }} )</p>
                </div>
                <div class="text-center col">
                    <p>Penerima</p>
                    <p class="pt-3"></p>
                    <p>( {{ detailDonasi.nama_amil }} )</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Donasi from '../api/Donasi'

    export default {
        name: 'Kwitansi',
        created() {
            this.donasi = new Donasi();
            this.donasi.get(this.idDonasi)
                .then(async response => {
                    this.detailDonasi = response.data
                })
                .catch(error => {
                   console.log(error.response);
                })
        },
        data() {
            return {
                detailDonasi: [],
            }
        },
        props: {
            idDonasi: String,
        },
        methods: {
            getLongDate(value) {
                const today = new Date(value)
                return today.toLocaleDateString('id-ID', {
					day: '2-digit',
					month: 'long',
					year: 'numeric',
				});
            },
            formatCurency(value) {
                let formatter = new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    maximumFractionDigits: 0,
                });

                return formatter.format(value);
            },
            noKwitansi(value) {
                return ('0000000' + value).substr(-8);
            },
            currencyToText(value) {
                let a = ['','Satu ','Dua ','Tiga ','Empat ', 'Lima ','Enam ','Tujuh ','Delapan ','Sembilan ','Sepuluh ','Sebelas ','Dua Belas ','Tiga Belas ','Empat Belas ','Lima Belas ','Enam Belas ','Tujuh Belas ','Delapan Belas ','Sembilan Belas '];
                let b = ['', 'Sepuluh ', 'Dua Puluh ','Tiga Puluh ','Empat Puluh ','Lima Puluh ', 'Enam Puluh ','Tujuh Puluh ','Delapan Puluh ','Sembilan Puluh '];
                let str = '';
                if (value > 2147483647) return 'Melebihi cakupan';
                let n = ('00000000000' + value).substr(-12).match(/^(\d{3})(\d{3})(\d{3})(\d{1})(\d{2})$/);
                if (!n) return;
                str += (n[1] != 0) ? (Number(n[1][0]) == 1 ? 'Seratus ' : Number(n[1]) > 99 ? a[Number(n[1][0])] + 'Ratus ' : '') + (a[Number(n[1])] || b[n[1][1]] + '' + a[n[1][2]]) + 'Miliar ' : '';
                str += (n[2] != 0) ? (Number(n[2][0]) == 1 ? 'Seratus ' : Number(n[2]) > 99 ? a[Number(n[2][0])] + 'Ratus ' : '') + (a[Number(n[2])] || b[n[2][1]] + '' + a[n[2][2]]) + 'Juta ' : '';
                str += (n[3] != 0) ? (n[3] == 1) ? 'Seribu ' : (Number(n[3][0]) == 1 ? 'Seratus ' : Number(n[3]) > 99 ? a[Number(n[3][0])] + 'Ratus ' : '') + (a[Number(n[3])] || b[n[3][1]] + '' + a[n[3][2]]) + 'Ribu ' : '';
                str += (n[4] != 0) ? (n[4] == 1) ? 'Seratus ' : (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Ratus ' : '';
                str += (Number(n[5]) !== 0) ? ((str !== '') ? '' : '') +
                            (a[Number(n[5])] || b[n[5][0]] + '' +
                                a[n[5][1]]) + '' : '';
                return str + "Rupiah";
            },
        },
        mounted() {
            setTimeout(this.$htmlToPaper, 1000, 'printKwitansi');
            setTimeout(this.$router.go, 1000, -1);
        }
    }
</script>